import { transform, isEqual } from 'lodash';

export const isArray = (arr: unknown) => arr !== null && Array.isArray(arr);

export function isObject<T extends Record<string, unknown>>(
  input: unknown
): input is T {
  return input !== null && !isArray(input) && typeof input === 'object';
}

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @param  {Boolean} includeId flag to control including ID with difference
 * @return {Object}        Return a new object who represent the diff
 */
export function diffDeep<T>(object: T, base: unknown, includeId = false): T {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function changes(object: any, base: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return transform(object, function (result: any, value, key) {
      if (!isEqual(value, base[key])) {
        if (Array.isArray(value) && Array.isArray(base[key])) {
          result[key] = [];

          // loop through each entry of array
          for (let i = 0; i < value.length; i++) {
            const base_entry = base[key].find(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (data: { id?: any }) => data?.id === value[i]?.id
            );

            if (base_entry) {
              if (!isEqual(value[i], base_entry)) {
                // get the differences
                result[key].push(changes(value[i], base_entry));
              } else {
                // We always need to include ID for array entries
                // even if there was no change - otherwise item
                // gets deleted when data written to database
                result[key].push({ id: value[i].id });
              }
            } else {
              result[key].push(value[i]);
            }
          }
        } else {
          result[key] =
            isObject(value) && isObject(base[key])
              ? changes(value, base[key])
              : value;

          // include ID field if applicable
          if (includeId) {
            result['id'] = base['id'];
          }
        }
      }
    });
  }
  return changes(object, base);
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function compareOrder(otherArray: any) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function (current: any) {
    return (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      otherArray.filter(function (other: any) {
        return other.id == current.id && other.order == current.order;
      }).length == 0
    );
  };
}
