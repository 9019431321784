







































































































































import Vue from 'vue';
import moment from 'moment';
import { cloneDeep, truncate } from 'lodash';
import VueDraggable from 'vuedraggable';
import { mapActions, mapGetters } from 'vuex';

import { IAd } from '@/models';
import { ErrorManager } from '@/models/error';

import AdvertisementDetailFormDialog from '@/components/advertisements/AdvertisementDetailFormDialog.vue';
import ConfirmationDialog from '../common/ConfirmationDialog.vue';
import { compareOrder } from '@/utils/helpers';

export default Vue.extend({
  components: {
    AdvertisementDetailFormDialog,
    ConfirmationDialog,
    VueDraggable
  },
  data: () => ({
    loading: false,
    sortBy: 'order',
    sortDesc: true,
    search: '',
    headers: [
      { text: '', value: 'drag', width: 40 },
      { text: 'Ad Image', value: 'image' },
      { text: 'Ad Link', value: 'url' },
      { text: 'Date', value: 'updated_at' },
      { text: 'Actions', value: 'actions', align: 'center', sortable: false }
    ],
    proxyItems: [] as IAd[],
    isDragging: false,

    errorMessage: '',
    detailErrorMessage: '',

    deleteErrorMessage: '',
    detailDeleteErrorMessage: '',
    deleteDialog: false,
    selectedAdvertisement: null as null | IAd,
    deleteInProgress: false
  }),

  computed: {
    ...mapGetters({ apps: 'altoleapApp/getAltoleapApps', ads: 'ad/getAds' }),

    errorOccurred(): boolean {
      return this.errorMessage.length > 1;
    }
  },

  watch: {
    ads: {
      handler() {
        this.proxyItems = cloneDeep(this.ads);
      },
      deep: true
    }
  },

  async mounted() {
    this.loading = true;
    if (!this.apps.length) {
      await this.fetchAltoleapApps();
    }
    this.fetchAds(this.apps[0].id)
      .then(() => {
        this.proxyItems = cloneDeep(this.ads);
      })
      .catch((error) => {
        this.errorMessage = ErrorManager.extractApiError(error);
      })
      .finally(() => (this.loading = false));
  },

  methods: {
    moment,
    truncate,
    ...mapActions({
      fetchAltoleapApps: 'altoleapApp/fetchAltoleapApps',
      fetchAds: 'ad/fetchAds',
      deleteAd: 'ad/deleteAd',
      updateAdOrder: 'ad/updateAdOrder'
    }),

    openAdFormDialog(ad: IAd, isEdit = false) {
      const advertisementDetailFormDialog = this.$refs
        .advertisementDetailFormDialog as InstanceType<
        typeof AdvertisementDetailFormDialog
      >;
      advertisementDetailFormDialog.open(ad, isEdit);
    },

    openAdDeleteDialog(advertisement: IAd) {
      this.clearAllDeleteErrors();
      this.selectedAdvertisement = advertisement;
      this.deleteDialog = true;
    },

    clearAllErrors(): void {
      this.errorMessage = '';
      this.detailErrorMessage = '';
    },

    clearAllDeleteErrors(): void {
      this.deleteErrorMessage = '';
      this.detailDeleteErrorMessage = '';
    },

    removeAd(item: IAd) {
      this.clearAllDeleteErrors();
      this.deleteInProgress = true;

      this.deleteAd({ appId: item.app, id: item.id })
        .then(() => {
          this.deleteDialog = false;
        })
        .catch((error) => {
          this.deleteErrorMessage = ErrorManager.extractApiError(error);
          if (error.response.data.detail) {
            this.detailDeleteErrorMessage = error.response.data.detail;
          }
        })
        .finally(() => {
          this.deleteInProgress = false;
        });
    },

    cancelAdvertisementDeleteDialog() {
      this.clearAllDeleteErrors();
      this.selectedAdvertisement = null;
      this.deleteDialog = false;
    },

    async updateItemOrder() {
      this.isDragging = false;

      this.clearAllErrors();

      // using proxyItems as the value itself is updated before function is called
      this.proxyItems.map((item, index: number) => {
        if (item.order !== index + 1) {
          item.order = index + 1;
        }
        return item;
      });

      await this.setItemOrderList();
    },

    async setItemOrderList() {
      const difference: IAd[] = this.proxyItems.filter(compareOrder(this.ads));

      this.clearAllErrors();

      if (difference.length) {
        this.loading = true;

        await Promise.all(
          difference.map(async (item) => {
            await this.updateAdOrder(item).catch((error) => {
              this.errorMessage = ErrorManager.extractApiError(error);
            });
          })
        ).finally(() => {
          this.loading = false;
        });
      }
    }
  }
});
