
























































































import Vue from 'vue';
import { PropValidator } from 'vue/types/options';

export default Vue.extend({
  name: 'ConfirmationDialog',

  model: {
    prop: 'value',
    event: 'change'
  },

  props: {
    value: {
      required: false
    },
    errorMessages: {
      type: [String, Array],
      default: () => []
    } as PropValidator<string | string[]>,
    errorDetailMessages: {
      type: [String, Array],
      default: () => []
    } as PropValidator<string | string[]>,
    title: String,
    message: String,
    persistent: Boolean
  },

  data() {
    return {
      internalLazyValue: this.value as unknown,
      showDetail: false
    };
  },

  computed: {
    internalValue: {
      get(): unknown {
        return this.internalLazyValue;
      },
      set(val: unknown) {
        if (val === this.internalLazyValue) return;

        this.internalLazyValue = val;

        // hack for passing multiple function on the emit values
        this.$emit('input', val);
        this.$emit('change', val);
      }
    },

    isActionsSlotProvided(): boolean {
      return !this.$scopedSlots.actions;
    },

    isContentSlotProvided(): boolean {
      return !this.$scopedSlots.content;
    },

    internalErrorMessages(): string[] {
      return this.genInternalMessages(this.errorMessages);
    },

    internalErrorDetailMessages(): string[] {
      return this.genInternalMessages(this.errorDetailMessages);
    },

    errorOccurred(): boolean {
      return this.internalErrorMessages.length > 0;
    },

    errorDetailOccurred(): boolean {
      return this.internalErrorDetailMessages.length > 0;
    }
  },

  watch: {
    value(val) {
      this.internalLazyValue = val;
    }
  },

  mounted() {
    if (this.isActionsSlotProvided) {
      console.warn(
        `${this.$options.name}: No actions slot provided, use scoped slot named 'actions'`
      );
    }
  },

  methods: {
    toggleDetailError() {
      this.showDetail = !this.showDetail;
    },

    genInternalMessages(messages: string | string[]): string[] {
      // is case message is an empty string
      if (!messages) return [];
      else if (Array.isArray(messages)) return messages;
      else return [messages];
    }
  }
});
