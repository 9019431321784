var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"transparent",staticStyle:{"min-inline-size":"max-content"},attrs:{"headers":_vm.headers,"search":_vm.search,"loading":_vm.loading,"items":_vm.ads,"items-per-page":25,"footer-props":{
    showFirstLastPage: true,
    itemsPerPageOptions: [25, 50, 100, -1]
  },"sort-by":"order","item-key":"id","hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"transparent",attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Advertisements")]),_c('v-spacer'),_c('v-text-field',{staticClass:"mx-4",staticStyle:{"max-width":"550px"},attrs:{"label":"Search","prepend-icon":"mdi-magnify","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"text-none",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.openAdFormDialog()}}},[_vm._v(" Create Ad ")]),_c('AdvertisementDetailFormDialog',{ref:"advertisementDetailFormDialog"}),(_vm.selectedAdvertisement)?_c('ConfirmationDialog',{attrs:{"persistent":"","max-width":"500","error-messages":_vm.deleteErrorMessage,"error-detail-messages":_vm.detailDeleteErrorMessage,"title":"Delete this Ad?","message":"This action cannot be undone"},on:{"clearAllErrors":_vm.clearAllDeleteErrors},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-spacer'),_c('v-btn',{attrs:{"small":"","text":"","disabled":_vm.deleteInProgress},on:{"click":function($event){$event.stopPropagation();return _vm.cancelAdvertisementDeleteDialog()}}},[_vm._v("Cancel ")]),_c('v-btn',{attrs:{"small":"","text":"","loading":_vm.deleteInProgress,"color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.removeAd(_vm.selectedAdvertisement)}}},[_vm._v("Yes ")])]},proxy:true}],null,false,1740310265),model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}}):_vm._e()],1),(_vm.errorOccurred)?_c('v-alert',{attrs:{"type":"error","dismissible":""}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()]},proxy:true},{key:"body",fn:function(ref){
  var items = ref.items;
return [_c('VueDraggable',{attrs:{"tag":"tbody","handle":".handle","ghost-class":"ghost"},on:{"start":function($event){_vm.isDragging = true},"end":_vm.updateItemOrder},model:{value:(_vm.proxyItems),callback:function ($$v) {_vm.proxyItems=$$v},expression:"proxyItems"}},[_vm._l((items),function(item,itemIndex){return [_c('tr',{key:itemIndex},[_c('td',{staticClass:"text-start",class:{
              handle: true
            },attrs:{"colspan":"1"}},[_c('v-icon',[_vm._v("mdi-drag-vertical")])],1),_c('td',{attrs:{"colspan":"1"}},[_c('v-img',{staticClass:"my-1",attrs:{"src":item.image,"height":"80","width":"180"}})],1),_c('td',{staticClass:"text-start",attrs:{"colspan":"1"}},[_c('a',{attrs:{"target":"_blank","href":item.url},domProps:{"textContent":_vm._s(
                _vm.truncate(item.url, {
                  length: 40
                })
              )}})]),_c('td',{staticClass:"text-start",attrs:{"colspan":"1"}},[_vm._v(" "+_vm._s(_vm.moment(item.updated_at).format('MMMM D, YYYY'))+" ")]),_c('td',{staticClass:"text-center",attrs:{"colspan":"1"}},[_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.openAdFormDialog(item, true)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.openAdDeleteDialog(item, true)}}},[_vm._v(" mdi-delete ")])],1)])]})],2)]}},{key:"no-data",fn:function(){return [_vm._v(" No advertisements available ")]},proxy:true},{key:"no-results",fn:function(){return [_vm._v(" No matching advertisements available ")]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }