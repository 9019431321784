






import Vue from 'vue';

import AdvertisementDataTable from '@/components/advertisements/AdvertisementDataTable.vue';

export default Vue.extend({
  name: 'AdvertisementPage',

  components: {
    AdvertisementDataTable
  }
});
