











































































































































import Vue from 'vue';
import { cloneDeep } from 'lodash';

import { Ad, AdFieldError, ErrorManager, IAd } from '@/models';
import { diffDeep } from '@/utils/helpers';
import { mapActions, mapGetters } from 'vuex';

export default Vue.extend({
  name: 'AdvertisementDetailFormDialog',

  data: () => ({
    loading: false,
    isEdit: false,
    dialog: false,
    dragover: false,
    imageLoading: false,
    imageUploaded: false,
    showDetail: false,

    advertisementOriginalCopy: null as null | IAd,
    advertisement: null as null | IAd,
    fieldError: new AdFieldError(),
    errorMessage: '',
    errorDetailMessage: '',
    cachedImage: '' as string | ArrayBuffer | null | undefined
  }),

  computed: {
    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    },
    errorDetailOccurred(): boolean {
      return this.errorDetailMessage.length > 0;
    },
    ...mapGetters({ apps: 'altoleapApp/getAltoleapApps' }),

    // TODO: LH - 2021-20-10 - look into multiple app support
    defaultAppId(): string {
      return this.apps[0].id;
    }
  },

  methods: {
    ...mapActions({
      updateAd: 'ad/updateAd',
      createAd: 'ad/createAd'
    }),

    toggleDetailError() {
      this.showDetail = !this.showDetail;
    },

    open(advertisement: IAd = new Ad(), isEdit = false) {
      this.advertisementOriginalCopy = advertisement;
      this.advertisement = cloneDeep(this.advertisementOriginalCopy);
      if (this.advertisement.image) {
        this.cachedImage = this.advertisement.image as string;
      }
      this.isEdit = isEdit;
      this.clearAllErrors();
      this.dialog = true;
      this.imageUploaded = false;
    },

    close() {
      this.dialog = false;
      this.cachedImage = '';
    },

    async onChange(e: DragEvent) {
      const files =
        e.dataTransfer?.files ?? (e.target as HTMLInputElement)?.files;

      if (files?.length) {
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
          this.cachedImage = e.target?.result;
        };
        reader.readAsDataURL(files[0]);
        this.advertisement!.image = files[0];
        this.imageUploaded = true;
      }
    },
    onDrop(e: DragEvent) {
      this.dragover = false;
      this.onChange(e);
    },

    inputClick() {
      const fileImage = this.$refs.fileImage as HTMLInputElement;
      fileImage.click();
    },

    clearAllErrors(): void {
      this.fieldError.clearErrors();
      this.errorMessage = '';
    },

    async saveAdvertisement() {
      this.clearAllErrors();

      this.loading = true;

      try {
        if (this.isEdit) {
          const advertisementDelta = diffDeep(
            this.advertisement,
            this.advertisementOriginalCopy,
            true
          );
          advertisementDelta!.id = this.advertisement!.id;
          await this.updateAd({
            appId: this.advertisement!.app,
            data: advertisementDelta
          });
        } else {
          this.advertisement!.app = this.defaultAppId;
          await this.createAd({
            appId: this.advertisement!.app,
            data: this.advertisement
          });
        }

        this.loading = false;

        this.close();
        //! https://github.com/microsoft/TypeScript/issues/36775
        // Typescript error code - TS1196
        //* LH - 2021-10-20 - Must be specified as any or unknown for error type predicate
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        this.loading = false;

        if (error.response) {
          // client received an error response that falls out of range 2xx
          if (AdFieldError.isAdFieldError(error.response.data)) {
            this.fieldError = new AdFieldError(error.response.data);
          }
          if (error.response.data.detail) {
            this.errorDetailMessage = error.response.data.detail;
          }
        }

        this.errorMessage = ErrorManager.extractApiError(error);
      }
    }
  }
});
